import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Session from '../components/Session';
import LabeledIconButton from '../components/LabeledIconButton';

function Admin() {
    const [backendData, setBackendData] = useState({});
    const [isEditing, setIsEditing] = useState({ username: false, email: false });
    const [formData, setFormData] = useState({ username: '', email: '' });
    const userId = Session.getUser()?._userId;
    const userClass = Session.getUser()?._accountType;
    return (
        <>
            <Header />
            <LabeledIconButton
                label='Manage Sponsor Organizations'
                icon='storefront'
                link='../admin/sponsors'
            >
            </LabeledIconButton>
        </>
    );
}
export default Admin;

