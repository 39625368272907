import React, { useState } from 'react';
import Header from '../components/Header';
import ThemeSwitch from '../components/ThemeSwitch';
import session from '../components/Session';


function Support() {
  const [submitted, setSubmitted] = useState(false);
  
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://truckstopnshop.cpsc4911.com/api/support', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const result = await response.json();
      console.log('Form submitted:', result);

      if (response.ok) {
        setSubmitted(true);
      } else {
        alert("An error occurred while submitting your request.");
      }

    } catch (e) {
      console.error('Error:', e);
    }
  };

  if (submitted) {
    return (
      <>
        <Header />
        <h1 style={{ textAlign: 'center' }}>Thank you, our representatives will reach out to you shortly!</h1>
      </>
    );
  }

  return (
    <>
      <Header />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <form 
          onSubmit={handleSubmit} 
          style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            width: '300px', 
            padding: '20px', 
            border: '1px solid #ccc', 
            borderRadius: '10px',
            backgroundColor: '#f9f9f9'
          }}
        >
          <h2 style={{ textAlign: 'center' }}>Need Help?</h2>
          <label>
            First Name:
            <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
          </label>
          <label>
            Last Name:
            <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
          </label>
          <label>
            Email:
            <input type="email" name="email" value={formData.email} onChange={handleChange} required />
          </label>
          <label>
            Phone Number:
            <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
          </label>
          <button type="submit" style={{ marginTop: '10px', padding: '10px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px' }}>
            Submit
          </button>
        </form>
      </div>
    </>
  );
}

export default Support;