import React, { useState, useEffect } from 'react';
import Session from './Session';

function ThemeSwitch() {
  const [theme, setTheme] = useState(Session.getTheme()); // Get the initial theme from Session

  useEffect(() => {
    const root = document.documentElement;
    switch (theme) {
      case 'dark':
        root.style.setProperty('--background-color-light', '#333');
        root.style.setProperty('--text-color-light', '#fff');
        root.style.setProperty('--header-bg-light', '#222');
        root.style.setProperty('--form-bg-light', '#444');
        break;
      case 'high-contrast':
        root.style.setProperty('--background-color-light', 'black');
        root.style.setProperty('--text-color-light', 'white');
        root.style.setProperty('--header-bg-light', 'blue');
        root.style.setProperty('--form-bg-light', 'yellow');
        break;
      default: // light mode
        root.style.setProperty('--background-color-light', 'white');
        root.style.setProperty('--text-color-light', 'black');
        root.style.setProperty('--header-bg-light', '#009699');
        root.style.setProperty('--form-bg-light', 'rgb(147, 222, 237)');
        break;
    }
  }, [theme]);

  const handleThemeChange = (e) => {
    const selectedTheme = e.target.value;
    setTheme(selectedTheme);
    Session.setTheme(selectedTheme); // Store the selected theme in Session
  };

  return (
    <div className="theme-switch">
      <label htmlFor="theme-select">Select Theme:</label>
      <select id="theme-select" value={theme} onChange={handleThemeChange}>
        <option value="light">Light Mode</option>
        <option value="dark">Dark Mode</option>
        <option value="high-contrast">High Contrast Mode</option>
      </select>
    </div>
  );
}

export default ThemeSwitch;
