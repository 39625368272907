import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import Session from '../components/Session';
import './SponsorDetails.css';

const SponsorDetails = () => {
    const { sponsor_id } = useParams();
    const [sponsor, setSponsor] = useState(null);
    const [sponsorUsers, setSponsorUsers] = useState([]);
    const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    useEffect(() => {
        const fetchSponsorDetails = async () => {
            try {
                const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}`);
                const data = await response.json();
                setSponsor(data);
                await fetchSponsorUsers();
            } catch (error) {
                console.error('Error fetching sponsor details:', error);
            }
        };
        fetchSponsorDetails();
    }, [sponsor_id]);

    const fetchSponsorUsers = async () => {
        try {
            const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}/SponsorUsers`);
            const data = await response.json();
            setSponsorUsers(data);
        } catch (error) {
            console.error('Error fetching sponsor users:', error);
        }
    };

    const handleSearchChange = async (e) => {
        const value = e.target.value;
        setSearchQuery(value);
        setErrorMessage('');
        if (value) {
            try {
                const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}/addUser/search?username=${value}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setSuggestions(data);
            } catch (error) {
                console.error('Error fetching suggestions:', error);
                setErrorMessage('Error fetching suggestions.');
            }
        } else {
            setSuggestions([]);
        }
    };

    const handleUserClick = (user) => {
        setSelectedUser(user);
        setSearchQuery(user.username);
        setSuggestions([]);
    };

    const handleAddUser = async () => {
        if (!selectedUser) {
            setErrorMessage("User not found.");
            return;
        }
        try {
            const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}/addUser`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: selectedUser.user_id }),
            });
            if (response.ok) {
                setErrorMessage('');
                setSearchQuery('');
                setSelectedUser(null);
                await fetchSponsorUsers();
            } else {
                setErrorMessage("Error adding user.");
            }
        } catch (error) {
            console.error("Error adding user:", error);
            setErrorMessage("Error adding user.");
        }
    };

    const handleOpenAddUserModal = () => {
        setIsAddUserModalOpen(true);
        setSearchQuery('');
        setSuggestions([]);
        setErrorMessage('');
    };

    const handleCloseAddUserModal = () => {
        setIsAddUserModalOpen(false);
        setSearchQuery('');
        setSelectedUser(null);
        setSuggestions([]);
    };

    async function generateLink(sponsor) {
        try {
            const response = await fetch(`${Session.getDomain()}/api/authentication/generateToken`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userID: sponsor.sponsor_id  }),
            });
            const result = await response.json();

            var URL = `https://truckstopnshop.cpsc4911.com?Token=${result.token}&sponsorID=${sponsor.sponsor_id}&sponsorName=${sponsor.sponsor_name}`
            var SponsorURLBtn = document.getElementById("SponsorURLBtn");
            navigator.clipboard.writeText(URL).then(function () {
                SponsorURLBtn.innerHTML = "Copied!";
                setTimeout(function () {
                    SponsorURLBtn.innerHTML = "Copy Invitation link";
                }, 1000);
    
            }, function (err) {
                console.error('Failed to copy text: ', err);
            })

        } catch (error) {
            console.error(error);
            
        }  
    }

    if (!sponsor) {
        return <div>Loading sponsor details...</div>;
    }

    const placeholderImage = 'https://via.placeholder.com/150';

    return (
        <>
            <Header />
            <div className="sponsor-details-container">
                <h1 className="sponsor-name">{sponsor.sponsor_name}</h1>
                <p className="sponsor-description">
                    {sponsor.sponsor_description || 'No description available.'}
                </p>
                <div className="sponsor-users-header">
                    <h2>Sponsor Users</h2>
                    <button className="add-sponsor-user-button" onClick={handleOpenAddUserModal}>Add Sponsor User</button>
                    <button id='SponsorURLBtn' className="add-sponsor-user-button" onClick={() => { generateLink(sponsor) }}>Copy Invitation link</button>
                </div>
                {sponsorUsers.length > 0 ? (
                    <ul className="sponsor-users-list">
                        {sponsorUsers.map(user => (
                            <li key={user.user_id} className="sponsor-user-item">
                                {user.username} - {user.email}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No sponsor users found.</p>
                )}
                <img
                    src={sponsor.logo ? sponsor.logo : placeholderImage}
                    alt={sponsor.sponsor_name || 'Sponsor Logo'}
                    className="sponsor-logo"
                />

                {/* Add Sponsor User Modal */}
                {isAddUserModalOpen && (
                    <div className="modal open">
                        <div className="modal-overlay" onClick={handleCloseAddUserModal}></div>
                        <div className="modal-content">
                            <button className="modal-close" onClick={handleCloseAddUserModal}>x</button>
                            <h2>Add Sponsor User</h2>

                            <div className="search-container">
                                <input
                                    type="text"
                                    placeholder="Search username"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    className="search-input"
                                />
                                {suggestions.length > 0 && (
                                    <ul className="suggestions-dropdown">
                                        {suggestions.map((user) => (
                                            <li key={user.user_id} onClick={() => handleUserClick(user)}>
                                                {user.username}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                            <button onClick={handleAddUser}>Add User</button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default SponsorDetails;