import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Session from '../components/Session';
import AdminSponsorCard from '../components/AdminSponsorCard';
function AdminSponsors() {
    const [sponsors, setSponsors] = useState([]);
    useEffect(() => {
        const fetchSponsors = async () => {
            try {
                const response = await fetch(`${Session.getDomain()}/api/admin/sponsors`);
                const data = await response.json();
                setSponsors(data);
                console.log(data);
            } catch (error) {
                console.error('Error fetching sponsors:', error);
            }
        };
        fetchSponsors();
    }, []);
    return (
        <>
            <Header />
            <div>
                {sponsors.map((sponsor) => (
                    <AdminSponsorCard key={sponsor.sponsor_id} sponsor={sponsor} />
                ))}
            </div>
        </>

    );
}
export default AdminSponsors; 