import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import ThemeSwitch from '../components/ThemeSwitch';
import session from '../components/Session';


function Catalog() {

  const [backendData, setBackendData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${session.getDomain()}/api/ebay/testAPi`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const result = await response.json();
        const jsonObject = JSON.parse(result);
        setBackendData(jsonObject.findItemsByCategoryResponse[0].searchResult[0].item); // Get list of item
        console.log(jsonObject.findItemsByCategoryResponse[0].searchResult[0].item[0])
        console.log(jsonObject.findItemsByCategoryResponse[0].searchResult[0].item[0].galleryURL[0]); // img url
        //console.log(jsonObject.findItemsByCategoryResponse[0].searchResult[0].item[0].sellingStatus[0].currentPrice[0].__value__); Find price

      } catch (e) {
        console.error('Error:', e);
      }
    };
    fetchData();
  }, []);



  return (
    <>
      <Header />
      <h1 style={{ textAlign: "center" }}>Catalog</h1>




      <div id='catalogContainer'>

        {
          backendData.length > 0 ? (
            backendData.map((item, index) => (
              <div className='catalogObject' key={index}>

                <img src={item.galleryURL[0]} alt={item.title} />
                <p>{item.title}</p>
                <p>$ {item.sellingStatus[0].currentPrice[0].__value__}</p>
                <p><i tabIndex="0" class="las la-cart-plus"></i></p>
              </div>
            ))
          ) : (
            <p>Loading...</p>
          )
        }

      </div>

    </>
  );
}

export default Catalog;