import User from '../models/user';

var Session = (function () {

    var getLoggedIn = function () {
        return localStorage.getItem("logged") || false;
    };

    var setLoggedIn = function (logged) {
        localStorage.setItem("logged", logged);

    };

    var getTheme = function () {
        return localStorage.getItem("theme") || 'light'; // Default to 'light' if no theme is set
    };
    
    var setTheme = function (theme) {
        localStorage.setItem("theme", theme); // Store the theme string directly
    };
    

    var getUser = function () {
        const userData = localStorage.getItem("user");
        if (userData) {
           // const { userId, username, email, accountType } = JSON.parse(userData);
            //return new User(userId, username, email, accountType);
            return JSON.parse(userData);
        }
        return null;
    };

    var setUser = function (user) {
        localStorage.setItem("user", JSON.stringify(user));
    };

    var endSession = function () {
        localStorage.clear();
        window.location.href = '/';
    };

    var getDomain = function () {
        //https://truckstopnshop.cpsc4911.com
        return "https://truckstopnshop.cpsc4911.com";
    };

    return {
        getLoggedIn: getLoggedIn,
        endSession: endSession,
        setLoggedIn: setLoggedIn,
        getDomain:getDomain,
        setUser: setUser,
        getUser: getUser,
        getTheme:getTheme,
        setTheme:setTheme
    }
  
  })();
  
  export default Session;

