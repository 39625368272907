import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import App from './pages/App';
import Sign from './pages/SignIn';
import Catalog from './pages/Catalog';
import Support from './pages/Support';
import Inbox from './pages/Inbox';
import About from './pages/About';
import AccountCreation from './pages/AccountCreation';
import Profile from './pages/Profile';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Admin from './pages/Admin';
import AdminSponsors from './pages/AdminSponsors';
import SponsorDetails from './pages/SponsorDetails';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>

    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/Support" element={<Support />} />
        <Route path="/Catalog" element={<Catalog />} />
        <Route path="/About" element={<About />} />
        <Route path="/signin" element={<Sign />} />
        <Route path="/AccountCreation" element={<AccountCreation />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path="/Inbox" element={<Inbox />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/admin/sponsors" element={<AdminSponsors />} />
        <Route path="/admin/sponsors/:sponsor_id" element={<SponsorDetails />} />
      </Routes>
    </Router>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
