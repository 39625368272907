import React, { useState } from 'react';
import logo from '../assets/trucklogo.png';
import Header from '../components/Header';
import ThemeSwitch from '../components/ThemeSwitch';
import session from '../components/Session';
const AccountType = require('../enums/enums');




function AccountCreation() {


  const params = new URLSearchParams(window.location.search);
  const sponsorObject = ({
    sponsorID: params.get("sponsorID"),
    sponsorName: params.get("sponsorName"),
    Token: params.get("Token")
  });


  const [sponsor, setSponsor] = useState(sponsorObject);
  const creation = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    try {
      const response = await fetch(`${session.getDomain()}/api/AccountCreation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(Object.fromEntries(formData.entries()))
      });

      const result = await response.json();
      alert(result.message);


      if (sponsor.sponsorName) {
        try {
          const response = await fetch(`${session.getDomain()}/api/admin/sponsors/addUserLink`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user_id: result.userID, sponsor_id: sponsor.sponsorID, token: sponsor.Token }),
          });
          if (response.ok) {
            alert("Added sponsor " + sponsor.sponsorName)

          } else {
            alert("Error adding sponsor, please contact admin or sponsor")
          }

        } catch (error) {
          alert("Error adding sponsor.");
        }
      }


    } catch (e) {
      console.error('Error:', e);
    }

  };






  return (
    <>
      <Header />
      {sponsor.sponsorName && (<h1 style={{ textAlign: "center", marginTop: "30px" }}>You have been invited by {sponsor.sponsorName} </h1>)}
      <form id="signForm" onSubmit={creation}>
        <img className="mb-4" src={logo} alt="Logo" width="72" height="57" />
        <h1 className="h3 mb-3 fw-normal">Enter Account Information</h1>

        <div className="form-floating">
          <select name="accountType" className="form-control" id="floatingSelect" aria-label="Account Type">
            <option value={AccountType.DRIVER}>Driver</option>
            <option value={AccountType.SPONSOR}>Sponsor</option>
            <option value={AccountType.ADMIN}>Admin</option>
          </select>
          <label htmlFor="floatingSelect">Account Type - Internal only</label>
        </div>

        <div className="form-floating">
          <input name="username" autoComplete='off' type="text" className="form-control" id="floatingInput" placeholder="Username" required />
          <label htmlFor="floatingInput">User Name</label>
        </div>
        <div className="form-floating">
          <input name="email" autoComplete='off' type="email" className="form-control" id="floatingInput" placeholder="test@test.com" required />
          <label htmlFor="floatingInput">Email</label>
        </div>
        <div className="form-floating">

        
          <input name="password" type="password" className="form-control" id="floatingPassword"
            placeholder="Password" pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$" title="Password must be at least 8 characters long, contain at least one letter, one number, and one special character."
            required />


          <label htmlFor="floatingPassword">Password</label>
        </div>
        <button className="btn btn-primary w-100 py-2" type="submit">Create Account</button>
      </form>
    </>

  );
}



export default AccountCreation;
